// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Spinner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Spinner.tsx");
  import.meta.hot.lastModified = "1734096720050.9111";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
export function Spinner({
  className,
  color = "blue"
}) {
  const colors = {
    blue: {
      background: "rgba(59, 130, 246, 0.4)",
      foreground: "rgba(59, 130, 246)"
    },
    white: {
      background: "rgba(255, 255, 255, 0.4)",
      foreground: "rgba(255, 255, 255)"
    },
    muted: {
      background: "#1C2433",
      foreground: "#3C4B62"
    },
    dark: {
      background: "rgba(18, 19, 23, 0.35)",
      foreground: "#1A1B1F"
    }
  };
  const currentColor = typeof color === "string" ? colors[color] : color;
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("animate-spin motion-reduce:hidden", className)}>
      <rect x="2" y="2" width="16" height="16" rx="8" stroke={currentColor.background} strokeWidth="3" />
      <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke={currentColor.foreground} strokeWidth="3" strokeLinecap="round" />
    </svg>;
}
_c = Spinner;
export function ButtonSpinner() {
  return <Spinner className="size-3" color={{
    foreground: "rgba(0, 0, 0, 1)",
    background: "rgba(0, 0, 0, 0.25)"
  }} />;
}
_c2 = ButtonSpinner;
var _c, _c2;
$RefreshReg$(_c, "Spinner");
$RefreshReg$(_c2, "ButtonSpinner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;